html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f8f8f8;
}

a {
  text-decoration: none;
}

.d-flex {
  display: flex;
}
.flex-grow-1 {
  flex-grow: 1;
}

.modal-backdrop {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.save-primary {
  background-color: #ba4947;
  color: white;
}

.save-secondary {
  border: 1px solid #ba4947;
  background-color: white;
  color: #ba4947;
}

.save-secondary:hover {
  background-color: #ba4947;
  color: white;
}

.dataTables_filter {
  /* display: inline-block !important; */
  float: right;
}

.dataTables_paginate {
  /* display: inline-block !important; */
  float: right;
}

.dataTables_length {
  display: inline-block;
}

.dataTables_info {
  display: inline-block;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: -2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 2px 4px 7px 3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 4px 7px 3px rgba(0, 0, 0, 0.247);
  -moz-box-shadow: 2px 4px 7px 3px rgba(0, 0, 0, 0.137);
}

input:checked + .slider {
  background-color: #ba4947;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ba4947;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
